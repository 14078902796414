import React from 'react';
import { env } from './api';

let x = "";

export const Main = () => <>
    <main>
        <div>
            <h2>List Wallet Assets</h2>
            <div>
                Enter the ID or public-key of a wallet.
            </div>

            <div>
                <input />
                <button>Check</button>
            </div>

            <div>
                <div></div>
            </div>

            <div>
                <div>waiting for OpenSea API...</div>
                <div>
                    <ul>
                        <li>a</li>
                        <li>b</li>
                        <li>c</li>
                    </ul>
                </div>
            </div>
        </div>

        {/* <a
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
        >
            Learn React
        </a> */}
    </main>

</>
export const Header = () => <>
    <header>
        <h1>CRA / Cloudflare</h1>
    </header>
</>
export const Footer = () => <>
    <footer>
        <div>
            <div>[Footer]</div>
            <div>trigger ci 1</div>
        </div>

        <div className="envVars">
            <span>Env</span><div><code>{env.environment}</code></div>
            <span>Branch</span><div><code>{env.branch}</code></div>
            <span>Commit</span><div><code>{env.commitSha}</code></div>
            <span>Built</span><div><code>{env.buildTimestamp.toLocaleString()}</code></div>
        </div>
    </footer>

</>