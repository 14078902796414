import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Footer, Header, Main } from './App';
import { configure } from 'mobx';

configure({ enforceActions: 'never' });

ReactDOM.render(
    <React.StrictMode>
        <Header />
        <Main />
        <Footer />
    </React.StrictMode>,
    document.getElementById('root')
);

